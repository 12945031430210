<template>
  <div>
    <PageTitle :title="displayText.pageTitle" icon="chevron_left" hideBtn @iconClick="$router.push({ name: 'RewardContentSetting' })" />
    <!-- <section> -->
    <!-- <p class="view-title">新增抽獎</p> -->
    <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/reward-content-setting' }">
          抽獎內容設定
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">新增抽獎</el-breadcrumb-item>
      </el-breadcrumb>
    </section> -->

    <section class="card-container">
      <div class="card-content">
        <p class="card-title">{{ displayText.basic.cardTitle }}</p>
        <BaseElForm
          ref="form"
          label-position="top"
          :model="formData"
          :rules="formRules"
        >
          <BaseElFormItem :label="displayText.basic.img" prop="img">
            <UploadButton
              :img="formData.img"
              :isAvatar="true"
              @change="loadImg"
            />
          </BaseElFormItem>
          <div class="content-col">
            <BaseElFormItem :label="displayText.basic.name.title" prop="name">
              <BaseElInput v-model="formData.name" :placeholder="displayText.basic.name.placeholder" />
            </BaseElFormItem>
            <BaseElFormItem :label="displayText.basic.mode" prop="mode">
              <GameModeSearch :model.sync="formData.mode" />
            </BaseElFormItem>
          </div>
          <div class="content-col">
            <BaseElFormItem :label="displayText.basic.date.title" prop="date">
              <el-date-picker
                v-model="formData.date"
                editable
                type="datetimerange"
                :range-separator="displayText.basic.date.rangeSeparator"
                format="yyyy-MM-dd HH:mm"
                :start-placeholder="displayText.basic.date.startPlaceholder"
                :end-placeholder="displayText.basic.date.endPlaceholder"
              />
            </BaseElFormItem>
            <BaseElFormItem :label="displayText.basic.selectReq.title" prop="selectReq">
              <BaseElSelect
                v-model="formData.selectReq"
                :placeholder="displayText.basic.selectReq.placeholder"
                multiple
                collapse-tags
              >
                <BaseElSelectOption
                  v-for="req in lotteryReq"
                  :key="req.type"
                  :label="req.name"
                  :value="req.value"
                />
              </BaseElSelect>

              <div class="pt-3 grid gap-15">
                <!-- 會員卡等級 -->
                <BaseElFormItem
                  v-if="includesReq('memberCard')"
                  prop="memberCard"
                >
                  <BaseElSelect
                    ref="memberCard"
                    v-model="formData.memberCard"
                    :placeholder="displayText.basic.memberCard.placeholder"
                    multiple
                  >
                    <BaseElSelectOption
                      v-for="(item, index) in levelList"
                      :key="index"
                      :label="item.name"
                      :value="item.level"
                    />
                  </BaseElSelect>
                </BaseElFormItem>
                <!-- 會員標籤 -->
                <BaseElFormItem v-if="includesReq('memberTag')" prop="memberTag">
                  <TagSearch
                    ref="memberTag"
                    :tags="tags"
                    :model.sync="formData.memberTag"
                    multiple
                    objKey="id"
                    :preserve-null="true"
                  />
                </BaseElFormItem>
                <!-- 總使用次數 -->
                <BaseElFormItem v-if="includesReq('totalUsed')" prop="totalUsed">
                  <BaseElInput
                    ref="totalUsed"
                    v-model="formData.totalUsed"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    :placeholder="displayText.basic.totalUsed.placeholder"
                    @input="inputCheck($event, 'totalUsed', 0, defaultRangeMax)"
                  />
                </BaseElFormItem>
                <!-- 每日用次數 -->
                <BaseElFormItem v-if="includesReq('dayUsed')" prop="dayUsed">
                  <BaseElInput
                    ref="dayUsed"
                    v-model="formData.dayUsed"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    :placeholder="displayText.basic.dayUsed.placeholder"
                    @input="inputCheck($event, 'dayUsed', 0, defaultRangeMax)"
                  />
                </BaseElFormItem>
                <div v-if="includesReq('costPoint')" class="flex" style="gap: 8px">
                  <BaseElFormItem prop="shopPointKey" class="w-full">
                    <BaseDataSelect
                      :value.sync="formData.shopPointKey"
                      :placeholder="displayText.basic.shopPointKey.placeholder"
                      :data-list="shopPointList"
                      objKey="key"
                      @clear="formData.costPoint = null"
                    />
                  </BaseElFormItem>

                  <BaseElFormItem prop="costPoint" class="w-full">
                    <BaseElInput
                      v-model="formData.costPoint"
                      style="width: 100%"
                      type="number"
                      :min="1"
                      :max="defaultRangeMax"
                      :disabled="!formData.shopPointKey"
                      :placeholder="displayText.basic.costPoint.placeholder"
                      @input="inputCheck($event, 'costPoint', 0, defaultRangeMax)"
                    >
                      <template #suffix>{{ displayText.basic.costPoint.suffix }}</template>
                    </BaseElInput>
                  </BaseElFormItem>
                </div>
              </div>
            </BaseElFormItem>
          </div>
          <div class="content-col">
            <BaseElFormItem :label="displayText.basic.againDelay.title" prop="againDelay">
              <template slot="label">
                <FormItemTooltipLabel :label="displayText.basic.againDelay.title" :tooltipWidth="200">
                  {{ displayText.basic.againDelay.tooltip }}
                </FormItemTooltipLabel>
              </template>
              <BaseElSwitch
                v-model="formData.againDelay"
                :inactive-text="displayText.basic.againDelay.inactiveText"
                :active-text="displayText.basic.againDelay.activeText"
              />
            </BaseElFormItem>
          </div>
        </BaseElForm>
      </div>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title mb-[8px]">
          <span class="text-danger">*</span>
          {{ displayText.info.title }}
        </p>
        <quillEditor v-model="formData.description" :options="editorOption" @input="formError.description = false" />
        <p v-show="formError.description" class="text-danger text-sm" style="padding-top: 5px">{{ displayText.info.placeholder }}</p>
      </div>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title mb-[8px]">{{ displayText.notice.title }}</p>
        <quillEditor v-model="formData.notice" :options="editorOption" />
      </div>
    </section>
    <section v-if="copy" class="card-container">
      <div class="card-content">
        <p class="card-title">{{ displayText.copy.title }}</p>
        <p v-for="item in checkItems" :key="item.id" class="my-[12px]">{{ item.name }}</p>
        <p>{{ displayText.copy.note }}</p>
      </div>
    </section>

    <section class="page-footer">
      <BaseElButton type="text" @click="$router.go(-1)">{{ displayText.btn.cancel }}</BaseElButton>
      <BaseElButton plain @click="showPreviewDialog = true">{{ displayText.btn.preview }}</BaseElButton>
      <BaseElButton type="primary" @click="createMemberGame">{{ displayText.btn.create }}</BaseElButton>
    </section>

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />

    <PreviewDialog
      v-if="showPreviewDialog"
      :type="lotteryType"
      :data="formData"
      @close="showPreviewDialog = false"
    />
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import GameModeSearch from '@/components/Search/lottery/GameModeSearch.vue'
import PreviewDialog from './Dialog/PreviewDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import imageMixin from '@/mixin/image'
import { noEmptyRules, rangeRules, defaultRangeMax } from '@/validation/index'
import { lotteryReqConfig } from '@/config/lottery'
import { find, forEach, filter, get, map } from 'lodash'
import formUtils from '@/utils/form'
// APIs
import { MemberConfig } from '@/api/member'
import { CreateMemberGame, FindMemberGame, CheckDuplicateLotteryAwardDetail } from '@/api/lottery/memberGame'

// Editor
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import TagSearch from '@/components/Search/TagSearch'
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import { usePermissions } from '@/use/permissions'
import { useTagStore } from '../Marketing/components/useTagStore'
import { useShop } from '@/use/shop'
import { useRouter, useRoute } from 'vue-router/composables'
import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'
import notifyMessage from '@/config/notifyMessage'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'LotteryCreate',
  components: {
    UploadButton,
    quillEditor,
    GameModeSearch,
    PreviewDialog,
    TagSearch,
    ImageCropper,
    FormItemTooltipLabel,
    BaseDataSelect,
  },
  mixins: [imageMixin],
  setup () {
    const { editorOption } = useEditor()
    const route = useRoute()
    const router = useRouter()
    const { shopPointList } = useShop()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const { tags, fetchTagsOnMounted } = useTagStore()
    fetchTagsOnMounted()
    const showPreviewDialog = ref(false)
    const form = ref(null)
    const loading = ref(false)
    const formError = reactive({
      description: false,
    })
    const formData = reactive({
      img: null,
      name: '',
      mode: '',
      date: '',
      selectReq: [],
      memberCard: null,
      totalUsed: null,
      dayUsed: null,
      memberTag: null,
      costPoint: null,
      againDelay: false,
      description: '',
      notice: '',
      shopPointKey: '',
    })
    const levelList = ref([])
    const duplicateRowDetail = ref(null)
    const checkItems = computed(() => {
      return map(duplicateRowDetail.value, (item) => {
        return {
          id: get(item, 'LotteryAward.id'),
          name: get(item, 'LotteryAward.name'),
        }
      })
    })
    const displayText = computed(() => {
      const data = {
        pageTitle: '新增抽獎',
        basic: {
          cardTitle: '基本設定',
          img: '頁面圖片',
          name: {
            title: '抽獎名稱',
            plaecholder: '請輸入',
          },
          mode: '抽獎方式',
          date: {
            title: '抽獎活動期間',
            rangeSeparator: '至',
            startPlaceholder: '開始日期',
            endPlaceholder: '結束日期',
          },
          selectReq: {
            title: '抽獎條件設定',
            placeholder: '請選擇抽獎條件',
          },
          memberCard: {
            placeholder: '請選擇會員卡等級',
          },
          totalUsed: {
            placeholder: '請輸入單一會員總次數限制',
          },
          dayUsed: {
            placeholder: '請輸入單一會員每日抽獎次數',
          },
          shopPointKey: {
            placeholder: '請選擇點數類型',
          },
          costPoint: {
            placeholder: '請輸入每次扣除的點數數量',
            suffix: '點',
          },
          againDelay: {
            title: '「再抽一次」按鈕延遲功能',
            tooltip: '若開啟此設定，則會員完成抽獎當下，「再抽一次」按鈕將於 4 秒後才能點擊',
            inactiveText: '關閉',
            activeText: '開啟',
          },
        },
        info: {
          title: '活動說明',
          placeholder: '請輸入資料',
        },
        notice: {
          title: '注意事項',
        },
        copy: {
          title: '同時複製以下獎項',
          note: '建立結束後請至獎項管理進行調整。',
        },
        btn: {
          cancel: '取消',
          preview: '預覽',
          create: '新增',
        }
      }
      return data
    })
    const formRules = computed(() => {
      return {
      // img: [noEmptyRules()],
        name: [noEmptyRules()],
        mode: [noEmptyRules()],
        date: [noEmptyRules()],
        selectReq: [noEmptyRules()],
        memberCard: [noEmptyRules()],
        totalUsed: [noEmptyRules(), rangeRules()],
        dayUsed: [noEmptyRules(), rangeRules()],
        memberTag: [noEmptyRules()],
        costPoint: [noEmptyRules()],
        shopPointKey: [noEmptyRules()],
      }
    })
    const useRequirementsTypePoint = computed(() => checkAction('adminView.memberGame.requirementsTypePoint'))
    const lotteryId = computed(() => get(route, 'params.id'))
    const copy = computed(() => get(route, 'query.copy'))
    const lotteryReq = computed(() => {
      if (!useRequirementsTypePoint.value) return filter(lotteryReqConfig, (item) => item.value !== 'costPoint')
      if (formData.mode.key === 'no-animation') return filter(lotteryReqConfig, (item) => item.value !== 'costPoint')
      return lotteryReqConfig
    })
    const lotteryType = computed(() => {
      let type = 'scratch'
      if (formData.mode.key === 'no-animation') type = 'noAnime'
      if (formData.mode.key === 'luck-spin-wheel') type = 'spinner'
      return type
    })
    const includesReq = (type) => {
      return find(formData.selectReq, (item) => item === type)
    }
    const getRequirements = () => {
      const reqs = []
      let pass = true
      forEach(formData.selectReq, (req) => {
        const conditions = formData[req]

        if (req === 'memberCard') {
          if (!conditions.length) pass = false
          reqs.push({
            type: req,
            levels: conditions,
          })
        }
        if (req === 'memberTag') {
          if (!conditions.length) pass = false
          reqs.push({
            type: req,
            tags: conditions,
          })
        }
        if (req === 'totalUsed' || req === 'dayUsed') {
          if (!conditions) pass = false
          reqs.push({
            type: req,
            times: conditions,
          })
        }
        if (req === 'costPoint') {
          if (!conditions) pass = false
          reqs.push({
            type: req,
            costAmount: conditions,
            shopPointKey: formData.shopPointKey,
          })
        }
      })
      return [reqs, pass]
    }
    const inputCheck = (val, target, min, max) => {
      if (!val) [target] = min
      else if (val < min) formData[target] = min
      else if (val > max) formData[target] = max
      else formData[target] = Number(val)
    }
    //= > 建立會員抽獎
    const createMemberGame = async () => {
      if (loading.value) return
      loading.value = true
      let formPass = true
      if (!(await formUtils.checkForm(form.value))) formPass = false
      if (!formData.description) {
        formError.description = true
        formPass = false
      }
      if (!formPass) {
        loading.value = false
        return
      }

      const [reqs, pass] = getRequirements()
      if (!pass) {
        loading.value = false
        return
      }
      const [, err] = await CreateMemberGame({
        shopId: shopId.value,
        image: formData.img ? formData.img.id : undefined,
        memberGameModeId: formData.mode.id,
        name: formData.name,
        startAt: formData.date[0],
        endAt: formData.date[1],
        description: formData.description,
        notice: formData.notice,
        requirements: reqs,
        againDelay: formData.againDelay,
        duplicateLotteryAwardByMemberGameId: copy.value ? lotteryId.value : undefined,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.createSuccess)
      router.go(-1)
    }
    //= > 取得member config
    const getMemberConfig = async () => {
      const [res, err] = await MemberConfig({
        shopId: shopId.value,
      })
      if (err) {
        console.log(err)
        window.$message.error(err)
        return
      }
      levelList.value = filter(res.levels, (item) => item.level > 0)
      console.log('levelList', levelList.value)
    }
    const findMemberGame = async () => {
      const [res, err] = await FindMemberGame({
        shopId: shopId.value,
        id: lotteryId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      syncFormData(res)
    }
    const checkDuplicateLotteryAwardDetail = async () => {
      const [res, err] = await CheckDuplicateLotteryAwardDetail({
        shopId: shopId.value,
        id: lotteryId.value,
      })
      if (err) return window.$message.error(err)
      duplicateRowDetail.value = res
    }
    const syncFormData = (res) => {
      const { Image, name, MemberGameMode, startAt, endAt, description, notice, requirements, againDelay } = res
      formData.img = Image
      formData.name = name
      formData.mode = MemberGameMode
      formData.date = dayjs(endAt).isBefore(dayjs()) ? null : [startAt, endAt]
      formData.description = description
      formData.notice = notice
      formData.againDelay = againDelay
      forEach(requirements, (req) => {
        const type = req.type
        if (type === 'memberCard') {
          formData.selectReq.push('memberCard')
          formData.memberCard = map(req.levels)
        }
        if (type === 'memberTag') {
          formData.selectReq.push('memberTag')
          formData.memberTag = map(req.tags)
        }
        if (type === 'totalUsed') {
          formData.selectReq.push('totalUsed')
          formData.totalUsed = req.times
        }
        if (type === 'dayUsed') {
          formData.selectReq.push('dayUsed')
          formData.dayUsed = req.times
        }
        if (type === 'costPoint') {
          formData.selectReq.push('costPoint')
          formData.costPoint = req.costAmount
          formData.shopPointKey = req.shopPointKey
        }
      })
    }
    onMounted(async () => {
      await getMemberConfig()
      if (lotteryId.value) await findMemberGame()
      if (copy.value) await checkDuplicateLotteryAwardDetail()
    })
    return {
      tags,
      formError,
      lotteryReq,
      defaultRangeMax,
      showPreviewDialog,
      editorOption,
      levelList,
      formData,
      formRules,
      lotteryType,
      includesReq,
      inputCheck,
      createMemberGame,
      form,
      shopPointList,
      loading,
      checkItems,
      copy,
      displayText,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .card-content {
  @apply px-[22px] py-[24px];
}
::v-deep .el-form-item__label {
  line-height: unset;
}

::v-deep .ql-container.ql-snow {
  @apply min-h-[200px];
}

.page-footer {
  @apply text-right;
}

::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}

::v-deep .el-date-editor {
  @apply w-full;
}
</style>
